import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`7th April 2021`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now send and handle multipart requests and responses. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/253"
          }}>{`#253`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3327"
          }}>{`#3327`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Send a multipart message.
WebClient client = WebClient.of("http://example.com/");
BodyPart part1 = BodyPart.of(ContentDisposition.of("form-data", "username"), "Armeria");
BodyPart part2 = BodyPart.of(ContentDisposition.of("form-data", "password"), "mypassword");
Multipart multipart = Multipart.of(part1, part2);
client.execute(multipart.toHttpRequest("/login"));

// Handle a multipart message.
Server.builder()
      .service("/login", (ctx, req) -> {
          Multipart multipart = Multipart.from(req);
          multipart.aggregate().thenApply(aggregated -> {
              assert aggregated.field("username").contentUtf8().equals("Armeria");
              assert aggregated.field("password").contentUtf8().equals("mypassword");
              ...
          }));
     });
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Armeria now provides various useful extensions and conversions for Scala. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3395"
          }}>{`#3395`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`See `}<a parentName="li" {...{
              "href": "/docs/advanced-scala"
            }}>{`Scala integration`}</a>{` for the full features.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now create a `}<a parentName="p" {...{
            "href": "type://StreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html"
          }}>{`type://StreamMessage`}</a>{` from a `}<inlineCode parentName="p">{`Path`}</inlineCode>{` or a `}<inlineCode parentName="p">{`File`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3344"
          }}>{`#3344`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Path path = Paths.get("...");
StreamMessage<HttpData> publisher = StreamMessage.of(path);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now filter or transform values of a `}<a parentName="p" {...{
            "href": "type://StreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html"
          }}>{`type://StreamMessage`}</a>{` using
`}<a parentName="p" {...{
            "href": "type://StreamMessage#filter(Predicate):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#filter(java.util.function.Predicate)"
          }}>{`type://StreamMessage#filter(Predicate)`}</a>{` or `}<a parentName="p" {...{
            "href": "type://StreamMessage#map(Function):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#map(java.util.function.Function)"
          }}>{`type://StreamMessage#map(Function)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3351"
          }}>{`#3351`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Filter.
StreamMessage<Integer> source = StreamMessage.of(1, 2, 3, 4, 5);
StreamMessage<Integer> even = source.filter(x -> x % 2 == 0);

// Transform.
StreamMessage<Integer> source = StreamMessage.of(1, 2, 3, 4, 5);
StreamMessage<Boolean> isEven = source.map(x -> x % 2 == 0);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now send a different response depending on the exception. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3209"
          }}>{`#3209`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3413"
          }}>{`#3413`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server.builder().exceptionHandler((ctx, cause) -> {
    if (cause instanceof RequestTimeoutException) {
        // The request timed out!
        return AggregatedHttpResponse.of(...);
    }

    // Return null to let ExceptionHandler.ofDefault() convert the exception.
    return null;
})...
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now convert an exception into an `}<a parentName="p" {...{
            "href": "type://RpcResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RpcResponse.html"
          }}>{`type://RpcResponse`}</a>{` in `}<a parentName="p" {...{
            "href": "type://THttpService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/thrift/THttpService.html"
          }}>{`type://THttpService`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3349"
          }}>{`#3349`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3383"
          }}>{`#3383`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`THttpService.builder()
            .addService("hello", helloService)
            .exceptionHandler((ctx, cause) -> {
                if (cause instanceof IllegalArgumentException) {
                    return RpcResponse.of(new CustomizedException("Bad Request!"));
                }
                ...
            })
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now set a response timeout and attributes using `}<a parentName="p" {...{
            "href": "type://WebClientRequestPreparation:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClientRequestPreparation.html"
          }}>{`type://WebClientRequestPreparation`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3347"
          }}>{`#3347`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3357"
          }}>{`#3357`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`WebClient client = ...;
client.prepare()
      .get("/my-service")
      .responseTimeout(Duration.ofSeconds(3))
      .attr(USER_ID, userId)
      .attr(USER_SECRET, secret)
      .execute();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify additional error details to a gRPC response when an exception is raised in a gRPC service.
`}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3307"
          }}>{`#3307`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3329"
          }}>{`#3329`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`GrpcService.builder()
           .exceptionMapping((cause, metadata) -> {
               if (throwable instanceof AuthError) {
                   metadata.put(KEY, toMetadata(cause))
                   return Status.UNAUTHENTICATED.withCause(cause);
               }
               ...
           })
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now customize the success condition of a metric. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3404"
          }}>{`#3404`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3410"
          }}>{`#3410`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`MetricCollectingService.builder(...)
                       .successFunction((context, log) -> {
                            final int statusCode = log.responseHeaders().status().code();
                            return (statusCode >= 200 && statusCode < 400) || statusCode == 404;
                        });
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now fluently build a `}<a parentName="p" {...{
            "href": "type://DecodingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/encoding/DecodingClient.html"
          }}>{`type://DecodingClient`}</a>{` using the `}<a parentName="p" {...{
            "href": "type://DecodingClientBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/encoding/DecodingClientBuilder.html"
          }}>{`type://DecodingClientBuilder`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3348"
          }}>{`#3348`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3372"
          }}>{`#3372`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`DecodingClient.builder()
              .autoFillAcceptEncoding(false)
              .strictContentEncoding(true)
              .newDecorator();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now fluently build an `}<a parentName="p" {...{
            "href": "type://HttpRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html"
          }}>{`type://HttpRequest`}</a>{` with a `}<inlineCode parentName="p">{`Publisher<HttpData>`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3343"
          }}>{`#3343`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`StreamMessage<HttpData> publisher = StreamMessage.of(...);
HttpRequest.builder()
           .method(HttpMethod.GET)
           .path("/")
           .content(MediaType.PLAIN_TEXT_UTF_8, publisher)
           .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now convert a stream of Protobuf Messages into
`}<a parentName="p" {...{
            "href": "https://datatracker.ietf.org/doc/rfc7464/"
          }}>{`JSON Text sequences`}</a>{` using an annotated service. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3394"
          }}>{`#3394`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@Get("/items")
@ProducesJsonSequences
public Publisher<MyProtobufMessage> protobufJsonSeqPublisher() {
    return StreamMessage.of(MyProtobufMessage.newBuilder()...build(),
                            MyProtobufMessage.newBuilder()...build());
}

@Get("/items")
@ProducesJsonSequences
public Stream<MyProtobufMessage> protobufJsonSeqPublisher() {
    return Stream.of(MyProtobufMessage.newBuilder()...build(),
                     MyProtobufMessage.newBuilder()...build());
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now customize the default service name of a `}<a parentName="p" {...{
            "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
          }}>{`type://RequestLog`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3232"
          }}>{`#3232`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3366"
          }}>{`#3366`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server.builder()
      .defaultServiceNaming(ctx -> {
          final ServiceConfig config = ctx.config();
          return config.route().patternString();
     });
// For a specific service.
Server.builder()
      .route().path("/")
      .defaultServiceNaming(...)
      ...
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now check if the current request is matched by any routes or not. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3365"
          }}>{`#3365`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3378"
          }}>{`#3378`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = ...
sb.decorator((delegate, ctx, req) -> {
    if (ctx.config().route().isFallback()) {
        // This request is not matched any routes.
    }
    return delegate.serve(ctx, req);
});
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://BraveService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/brave/BraveService.html"
          }}>{`type://BraveService`}</a>{` does not trace requests for `}<a parentName="p" {...{
            "href": "type://TransientService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/TransientService.html"
          }}>{`type://TransientService`}</a>{` such as
`}<a parentName="p" {...{
            "href": "type://HealthCheckService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/healthcheck/HealthCheckService.html"
          }}>{`type://HealthCheckService`}</a>{` anymore. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3382"
          }}>{`#3382`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`You should specify `}<a parentName="li" {...{
              "href": "type://TransientServiceOption#WITH_TRACING:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/TransientServiceOption.html#WITH_TRACING"
            }}>{`type://TransientServiceOption#WITH_TRACING`}</a>{` if you want to trace them.`}</li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HealthCheckService.builder()
                  .transientServiceOptions(WITH_TRACING)
                  ...
                  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now clean up resources by overriding `}<a parentName="p" {...{
            "href": "type://FilteredStreamMessage#onCancellation(Subscriber):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/FilteredStreamMessage.html#onCancellation(org.reactivestreams.Subscriber)"
          }}>{`type://FilteredStreamMessage#onCancellation(Subscriber)`}</a>{`
when `}<inlineCode parentName="p">{`Subscription.cancel()`}</inlineCode>{` is called. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3375"
          }}>{`#3375`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`new MyFilteredHttpResponse(res) {
    ...
    @Override
    protected void onCancellation(Subscriber<? super U> subscriber) {
        // Clean up resources.
    }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now easily set 'cookie' or 'set-cookie' headers. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3388"
          }}>{`#3388`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3391"
          }}>{`#3391`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Cookie cookie = Cookie.of("cookie", "value");
RequestHeaders headers = RequestHeaders.builder(HttpMethod.GET, "/")
                                       .cookie(cookie)
                                       .build();
assert headers.cookies().equals(Cookies.of(cookie));

Cookie setCookie1 = ...
Cookie setCookie2 = ...
ResponseHeaders headers = ResponseHeaders.builder(HttpStatus.OK)
                                         .cookies(setCookie1, setCookie2)
                                         .build();
assert headers.cookies().equals(Cookies.of(setCookie1, setCookie2));
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<inlineCode parentName="p">{`req.root_id`}</inlineCode>{` `}<a parentName="p" {...{
            "href": "type://BuiltInProperty:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/BuiltInProperty.html"
          }}>{`type://BuiltInProperty`}</a>{` to log the ID of `}<a parentName="p" {...{
            "href": "type://RequestContext#root():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html#root()"
          }}>{`type://RequestContext#root()`}</a>{`.
`}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3429"
          }}>{`#3429`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3433"
          }}>{`#3433`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use Thrift 0.14.0 with the new `}<inlineCode parentName="p">{`armeria-thrift0.14`}</inlineCode>{` module. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3470"
          }}>{`#3470`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3422"
          }}>{`#3422`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use OAuth 2.0 related features with the new `}<inlineCode parentName="p">{`armeria-oauth2`}</inlineCode>{` module. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2268"
          }}>{`#2268`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2840"
          }}>{`#2840`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://datatracker.ietf.org/doc/rfc7662/"
            }}>{`OAuth 2.0 server-side token authorization`}</a>{` using
`}<a parentName="li" {...{
              "href": "type://OAuth2TokenIntrospectionAuthorizer:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/auth/oauth2/OAuth2TokenIntrospectionAuthorizer.html"
            }}>{`type://OAuth2TokenIntrospectionAuthorizer`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://datatracker.ietf.org/doc/html/rfc6749#section-4.4"
            }}>{`Client credential grants`}</a>{` using
`}<a parentName="li" {...{
              "href": "type://OAuth2ClientCredentialsGrant:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/auth/oauth2/OAuth2ClientCredentialsGrant.html"
            }}>{`type://OAuth2ClientCredentialsGrant`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://datatracker.ietf.org/doc/html/rfc6749#section-4.3"
            }}>{`Resource Owner Password Credentials Grant`}</a>{`
using `}<a parentName="li" {...{
              "href": "type://OAuth2ResourceOwnerPasswordCredentialsGrant:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/auth/oauth2/OAuth2ResourceOwnerPasswordCredentialsGrant.html"
            }}>{`type://OAuth2ResourceOwnerPasswordCredentialsGrant`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://datatracker.ietf.org/doc/rfc7009/"
            }}>{`OAuth 2.0 Token Revocation`}</a>{` using `}<a parentName="li" {...{
              "href": "type://TokenRevocation:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/auth/oauth2/TokenRevocation.html"
            }}>{`type://TokenRevocation`}</a></li>
        </ul>
        <Tip mdxType="Tip">
          <p parentName="li">{`  OAuth 2.0 features are currently experimental, so you should be careful using the feature.`}</p>
        </Tip>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<a parentName="p" {...{
            "href": "https://jakarta.ee/specifications/restful-ws/"
          }}>{`Jakarta RESTful Web Services`}</a>{`
on top of Armeria with the `}<inlineCode parentName="p">{`armeria-resteasy`}</inlineCode>{` module. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3285"
          }}>{`#3285`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3296"
          }}>{`#3296`}</a></p>
        <Tip mdxType="Tip">
          <p parentName="li">{`  `}<inlineCode parentName="p">{`armeria-resteasy`}</inlineCode>{` is currently experimental, so you should be careful using the feature.`}</p>
        </Tip>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`Various improvements for documentation. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3237"
        }}>{`#3237`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3289"
        }}>{`#3289`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3361"
        }}>{`#3361`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3390"
        }}>{`#3390`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3401"
        }}>{`#3401`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3427"
        }}>{`#3427`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`You no longer see `}<inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` in `}<a parentName="li" {...{
          "href": "type://HttpResponseDecoder"
        }}>{`type://HttpResponseDecoder`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3036"
        }}>{`#3036`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3407"
        }}>{`#3407`}</a></li>
      <li parentName="ul">{`You no longer see `}<inlineCode parentName="li">{`405 Method Not Allowed`}</inlineCode>{` when the exact and param path are defined with
different HTTP methods. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3330"
        }}>{`#3330`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3340"
        }}>{`#3340`}</a></li>
      <li parentName="ul">{`You no longer see `}<inlineCode parentName="li">{`Address family not supported by protocol`}</inlineCode>{` or `}<inlineCode parentName="li">{`Connection refused`}</inlineCode>{` error anymore
on certain machines with IPv6 enabled. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3425"
        }}>{`#3425`}</a></li>
      <li parentName="ul">{`The Unicode characters like emojis in a JSON response are now rendered correctly
in `}<a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3396"
        }}>{`#3396`}</a></li>
      <li parentName="ul">{`You no longer see the wrong response body when the payload violates the protocol or is too large. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3419"
        }}>{`#3419`}</a></li>
      <li parentName="ul">{`You can now use the Thrift client and service that is generated by
`}<a parentName="li" {...{
          "href": "https://issues.apache.org/jira/browse/THRIFT-2469"
        }}><inlineCode parentName="a">{`java:fullcamel`}</inlineCode></a>{` option of Thrift compiler.
`}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3269"
        }}>{`#3269`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3360"
        }}>{`#3360`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3369"
        }}>{`#3369`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`SmartLifecycle`}</inlineCode>{` for Armeria server graceful shutdown is only created when the Armeria server is created
by Spring integration. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3300"
        }}>{`#3300`}</a></li>
      <li parentName="ul">{`You can now use `}<a parentName="li" {...{
          "href": "https://scalapb.github.io/docs/sealed-oneofs/"
        }}>{`Sealed oneofs`}</a>{` message from ScalaPB
with JSON for gRPC and annotated services. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3342"
        }}>{`#3342`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3394"
        }}>{`#3394`}</a></li>
      <li parentName="ul">{`You no longer see `}<inlineCode parentName="li">{`CancellationException`}</inlineCode>{` when an `}<a parentName="li" {...{
          "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
        }}>{`type://HttpResponse`}</a>{` is fully consumed on server-side.
`}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3387"
        }}>{`#3387`}</a></li>
      <li parentName="ul">{`You no longer see `}<a parentName="li" {...{
          "href": "type://ClosedSteamException"
        }}>{`type://ClosedSteamException`}</a>{` in Jetty service when it fails to write to an
`}<a parentName="li" {...{
          "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
        }}>{`type://HttpResponse`}</a>{`. `}<inlineCode parentName="li">{`EofException`}</inlineCode>{` is raised instead. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3412"
        }}>{`#3412`}</a></li>
      <li parentName="ul">{`You no longer see `}<inlineCode parentName="li">{`IllegalStateException`}</inlineCode>{` when `}<a parentName="li" {...{
          "href": "type://RequestContextHooks:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/reactor3/RequestContextHooks.html"
        }}>{`type://RequestContextHooks`}</a>{` is enabled. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3441"
        }}>{`#3441`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3442"
        }}>{`#3442`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`ArmeriaServerHttpResponse`}</inlineCode>{` of Spring Webflux integration now correctly propagates
Reactor's `}<a parentName="li" {...{
          "href": "https://projectreactor.io/docs/core/release/reference/#context"
        }}><inlineCode parentName="a">{`Context`}</inlineCode></a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3439"
        }}>{`#3439`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3443"
        }}>{`#3443`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://ServiceRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html"
          }}>{`type://ServiceRequestContext`}</a>{` is added to the parameter of `}<inlineCode parentName="p">{`handleMessage`}</inlineCode>{` in
`}<a parentName="p" {...{
            "href": "type://AbstractUnaryGrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/protocol/AbstractUnaryGrpcService.html"
          }}>{`type://AbstractUnaryGrpcService`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3403"
          }}>{`#3403`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`The return type is also changed to `}<inlineCode parentName="li">{`CompletionStage`}</inlineCode>{`. `}<a parentName="li" {...{
              "href": "https://github.com/line/armeria/issues/3409"
            }}>{`#3409`}</a></li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`protected abstract CompletionStage<byte[]> handleMessage(
        ServiceRequestContext ctx, byte[] message);

protected final CompletionStage<ByteBuf> handleMessage(
        ServiceRequestContext ctx, ByteBuf message) {...}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://GrpcStatusFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/grpc/GrpcStatusFunction.html"
          }}>{`type://GrpcStatusFunction`}</a>{` now extends `}<inlineCode parentName="p">{`BiFunction<Throwable, Metadata, Status>`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3329"
          }}>{`#3329`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The `}<inlineCode parentName="p">{`numberSteps`}</inlineCode>{` method in `}<a parentName="p" {...{
            "href": "type://WeightRampingUpStrategyBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/WeightRampingUpStrategyBuilder.html"
          }}>{`type://WeightRampingUpStrategyBuilder`}</a>{` is now changed to `}<inlineCode parentName="p">{`totalSteps`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3377"
          }}>{`#3377`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Bucket4J 4.10.0 → 6.2.0`}</li>
      <li parentName="ul">{`Curator 4.3.0 → 5.1.0`}
        <ul parentName="li">
          <li parentName="ul">{`ZooKeeper 3.5.8 → 3.6.2`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Dropwizard 2.0.18 → 2.0.20`}</li>
      <li parentName="ul">{`Dropwizard Metrics 4.1.17 → 4.1.18`}</li>
      <li parentName="ul">{`gRPC 1.35.0 → 1.36.1`}</li>
      <li parentName="ul">{`Jackson 2.12.0 → 2.12.2`}</li>
      <li parentName="ul">{`java-jwt 3.12.1 → 3.14.0`}</li>
      <li parentName="ul">{`Jetty 9.4.36 → 9.4.39`}</li>
      <li parentName="ul">{`Micrometer 1.6.3 → 1.6.5`}</li>
      <li parentName="ul">{`Netty 4.1.58 → 4.1.63`}
        <ul parentName="li">
          <li parentName="ul">{`Netty TCNative BoringSSL 2.0.36 → 2.0.38`}</li>
          <li parentName="ul">{`Netty io_uring transport 0.0.3 → 0.0.5`}</li>
        </ul>
      </li>
      <li parentName="ul">{`OpenSAML 3.4.5 → 3.4.6`}
        <ul parentName="li">
          <li parentName="ul">{`Shibboleth java-support 7.5.1 → 7.5.2`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Reactor 3.4.2 → 3.4.4`}
        <ul parentName="li">
          <li parentName="ul">{`Reactor Kotlin extensions 1.1.2 → 1.1.3`}</li>
        </ul>
      </li>
      <li parentName="ul">{`RxJava 3.0.9 → 3.0.11, 2.2.20 → 2.2.21`}</li>
      <li parentName="ul">{`ScalaPb 0.10.10 → 0.11.0`}</li>
      <li parentName="ul">{`Spring Boot 2.4.2 → 2.4.4`}</li>
      <li parentName="ul">{`Tomcat 9.0.41 → 9.0.44, 8.5.61 → 8.5.64`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['andrey-tpt', 'anuraaga', 'ateirney', 'eisig', 'eugene70', 'ghkim3221', 'hyangtack', 'heka1024', 'heowc', 'hexoul', 'ikhoon', 'JunoJunho', 'kojilin', 'ks-yim', 'mauhiz', 'max904-github', 'minwoox', 'nirvanarsc', 'okue', 'policeman-kh', 'probepark', 'richieyan', 'selectAll', 'szeiger', 'trustin', 'Waynefn']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      